<template>
  <div class="content-0to5 star">
    <app-form-rate
      v-model="inputValue"
      :isStar="question.stars.active"
      :useStarNumbers="question.stars.numbers"
      :isGradient="question.gradient"
      :items="returnMyItems"
      v-on:change.native="response(inputValue)"
      :textColor="colors.actionText"
      :labelColor="colors.splashColor"
      :hoverColor="colors.buttonHover"
      :bgColor="colors.actionColor"
      item-text="value"
      :min-label="question.startLabel"
      :max-label="question.endLabel"
    />
  </div>
</template>

<script>
import { gradientMaker } from '@/_helpers'
export default {
  name: 'app5num',
  props: ['question', 'colors'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: '',
      items: [
        { value: 1, rating: 0 },
        { value: 2, rating: 25 },
        { value: 3, rating: 50 },
        { value: 4, rating: 75 },
        { value: 5, rating: 100 }
      ]
    }
  },
  created () {
    if (!this.question.stars) {
      this.question.stars = {}
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.response('skip')
      }
    })
  },
  computed: {
    returnMyItems () {
      const item = [...this.items]
      if (!this.question.stars.active) {
        gradientMaker.checkGradientAndReturnTheColors(this.question, item)
      }
      return item
    }
  },
  methods: {
    async response (value) {
      if (value === 'skip') {
        this.$emit('value-changed', { skip: true })
        this.$emit('question-answered', true)
      } else {
        let val = ''
        let rate = ''
        if (this.question.type === 'csat') {
          this.items.map(item => {
            if (item.value === value) {
              val = item.value
              rate = item.rating
            }
          })
          this.$emit('value-changed', { rating: rate, value: val })
        } else {
          this.$emit('value-changed', { rating: rate })
        }

        this.$emit('question-answered', true)
      }
    }
  }
}
</script>
<style lang="scss">
.content-0to5 {
  .star-span {
    left: 18.5px !important;
  }
  .rating__label--min {
    width: 100px;
    word-break: break-word;
    text-align: left;
  }
  .rating__label--max {
    width: 100px;
    word-break: break-word;
    text-align: right;
  }
}
</style>
